<!--试卷设置-->
<template>
  <div class="content">
    <div class="nav">
      <div @click="go">&lt;</div>
      <div style="color: #c8c8c8;cursor: pointer;" @click="go">考试管理/</div>
      <div>层级一：{{ des.name }} 品牌： {{this.des.brandId==1?'嘉顿肯妮':this.des.brandId==2?'瑞妮朵拉':this.des.brandId==3?'素颜+':'外部学员'}}</div>
    </div>
    <div class="contentmain" style="display:flex;justify-content:space-between;border-bottom:1px solid rgba(96, 98, 102,0.1);margin-bottom: 20px">
      <div class="title">
        <div class="line"></div>
        <div>时间设置</div>
        <el-input
          style="width: 58px; margin: 0 10px"
          v-model="examTime"
        
        ></el-input>
        <div>分钟</div>
      </div>

       <div class="title">
        <div class="line"></div>
        <div>考试须知</div>
        <el-button
            style="
              width: 77px;
              height: 34px;
              line-height: 11px;
              margin-left: 20px;
              border: 1px solid #409eff;
              color: #409eff;
            "
            @click="add2"
            >+添加</el-button
          >
      </div>

       <div class="title">
        <div class="line"></div>
        <div>考试规则</div>
         <el-button
            style="
              width: 77px;
              height: 34px;
              line-height: 11px;
              margin-left: 20px;
              border: 1px solid #409eff;
              color: #409eff;
            "
            @click="add3"
            >+添加</el-button
          >
      </div>
    </div>
    
    <div style="display: flex;justify-content: space-between;">
      <div class="contentmain">
        <div class="title">
          <div class="line"></div>
          <div>试卷设置</div>
        </div>
        <div class="row">
          <div
            style="
              color: #cacaca;
              width: 72px;
              text-align: center;
              border-left: 1px solid #e9e9e9;
              border-bottom: none;
            "
          >
            题型
          </div>
          <div
            style="border-bottom: none"
            v-for="(item, index) in inforObj"
            :key="index"
          >
            {{ item.questionTypeName }}
          </div>
        </div>
        <div class="row">
          <div
            style="
              color: #cacaca;
              width: 72px;
              text-align: center;
              border-left: 1px solid #e9e9e9;
            "
          >
            题量
          </div>
          <div  v-for="(item, index) in inforObj"
            :key="index">
            <el-select v-model="item.questionCount" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- <div>
            <el-select v-model="value1" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <el-select v-model="value2" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <el-select v-model="value3" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <el-select v-model="value4" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <el-select v-model="value5" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
        </div>
        <div class="row2">
          <div
            style="
              color: #cacaca;
              width: 72px;
              text-align: center;
              border-left: 1px solid #e9e9e9;
            "
          >
            分数
          </div>
          <el-input
            v-for="(item, index) in inforObj"
            :key="index"
            v-model="item.questionScore"
            placeholder="单题分数"
            style="text-align: center"
            type="number"
            @keyup.native="prevent($event,index)"
          ></el-input>
          <!-- <el-input
            v-model="input1"
            placeholder="输入分数"
            style="text-align: center"
            type="number"
            @keyup.native="prevent($event)"
          ></el-input>
          <el-input
            v-model="input2"
            placeholder="输入分数"
            style="text-align: center"
            type="number"
            @keyup.native="prevent($event)"
          ></el-input>
          <el-input
            v-model="input3"
            placeholder="输入分数"
            style="text-align: center"
            type="number"
            @keyup.native="prevent($event)"
          ></el-input>
          <el-input
            v-model="input4"
            placeholder="输入分数"
            style="text-align: center"
            type="number"
            @keyup.native="prevent($event)"
          ></el-input>
          <el-input
            v-model="input5"
            placeholder="输入分数"
            style="text-align: center"
            type="number"
            @keyup.native="prevent($event)"
          ></el-input> -->
        </div>
        <div class="row" style="color: #bababa">
          <div
            style="
              color: #cacaca;
              width: 72px;
              text-align: center;
              border-left: 1px solid #e9e9e9;
            "
          >
            合计
          </div>
          <div v-if="inforObj.length>=1">{{ total1 || 0 }}</div>
          <div v-if="inforObj.length>=2">{{ total2 || 0 }}</div>
          <div v-if="inforObj.length>=3">{{ total3 || 0}}</div>
          <div v-if="inforObj.length>=4">{{ total4 || 0}}</div>
          <div v-if="inforObj.length>=5">{{ total5 || 0}}</div>
        </div>
      </div>
      <div class="contentmain" style="margin-left: 0">
        <div class="title" style="margin-bottom: 14px">
          <div class="line"></div>
          <div>其他考题设置</div>
          <el-button
            style="
              width: 77px;
              height: 34px;
              line-height: 11px;
              margin-left: 20px;
              border: 1px solid #409eff;
              color: #409eff;
            "
            @click="add"
            >+添加</el-button
          >
        </div>
        <el-table
          :data="tableData"
          border
          style="width: 100%; max-height: 164px; overflow: auto"
          class="rh"
        >
          <el-table-column
            prop="name"
            label="名称"
            width="204px"
            align="center"
          >
          </el-table-column>
          <el-table-column label="操作" width="180px" align="center">
            <template slot-scope="scope">
              <div>
                <el-link
                  type="primary"
                  :underline="false"
                  @click="details(scope.row)"
                  >查看详情</el-link
                >
                <el-link
                  type="danger"
                  :underline="false"
                  style="margin-left: 23px"
                  @click="del(scope.row,scope.$index)"
                  >删除</el-link
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="operation">
      <div>
        <div
          style="
            width: 72px;
            height: 41px;
            line-height: 41px;
            justify-content: center;
            border: 1px solid #e9e9e9;
            border-right: none;
            color: #cacaca;
          "
        >
          总分
        </div>
        <!-- <el-input v-model="totals" style="width: 111px" disabled></el-input> -->
        <div
          style="
            width: 111px;
            height: 41px;
            line-height: 41px;
            text-align: center;
            border: 1px solid #e9e9e9;
            color: #cacaca;
          "
        >
          {{ totals }}
        </div>
        <div style="font-size: 14px; color: #cacaca; line-height: 40px">
          *满分应为100
        </div>
      </div>
      <div>
        <el-button type="primary" @click='saveinfo'>保存</el-button>
      </div>
    </div>
    <div class="bottomcontent">
      <div class="title">
        <div class="line"></div>
        <div>学员信息</div>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
        "
      >
        <div style="width: 221px; display: flex; align-items: center">
          <el-input
            v-model="input"
            placeholder="输入名称"
            class="cinput ac"
            @keyup.enter.native="search"
          ></el-input>
          <div
            style="
              display: flex;
              height: 40px;
              width: 40px;
              align-items: center;
              background: #f7f7f7;
            "
          >
            <img
              v-if="isshow"
              src="../../images/search2.png"
              alt=""
              @click="search"
              style="background: #f7f7f7; width: 25px; height: 25px"
            />
            <img
              v-if="isshow == false"
              src="../../images/search1.png"
              alt=""
              style="background: #f7f7f7; width: 25px; height: 25px"
            />
          </div>
        </div>
        <div>
          <el-cascader
            @change="selectcity"
            placeholder="地区选择"
            :options="citys"
            class="ac"
            clearable
            v-model="code"
          ></el-cascader>
          <el-select
            v-model="storeId"
            placeholder="店铺筛选"
            class="ac"
            style="margin: 0 31px"
          >
            <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select v-model="passState" placeholder="状态" class="ac">
            <el-option
              v-for="item in passList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <el-table :data="tableData2" border style="width: 100%">
        <el-table-column prop="name" label="姓名" align="center">
        </el-table-column>
        <el-table-column prop="date" label="地区" align="center">
          <template slot-scope="scope">
            <div>
              {{scope.row.provinceName}}-{{scope.row.cityName}}-{{scope.row.areaName}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="storeName" align="center" label="门店">
        </el-table-column>
        <el-table-column prop="name" align="center" label="成绩">
          <template slot-scope="scope">
            <div style="color: #3ad500" v-if='scope.row.levelId!=des.id'>通过</div>
            <div style="color: #FF1B0B" v-if='scope.row.levelId==des.id'>未通过</div>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="操作">
          <template slot-scope="scope">
            <div>
              <el-link type="primary" :underline="false" @click="peopledetails(scope.row,scope.row.levelId==des.id)"
                >查看详情</el-link
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        style="
          display: flex;
          justify-content: center;
          margin: 49px 0;
          padding-bottom: 49px;
        "
      >
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[5, 10, 20]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="805px"
      :before-close="handleClose"
    >
      <div class="pup">
        <div class="line"></div>
        <div class="title">添加试题</div>
      </div>
      <el-form label-position="right" label-width="120px" class="pupform">
        <el-form-item label="试题名称：">
          <el-input v-model="name" style="width: 502px"></el-input>
        </el-form-item>
        <el-form-item label="介绍：">
          <el-input
            v-model="introduce"
            style="width: 502px"
            type="textarea"
            :rows="5"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">删 除</el-button>
        <el-button type="primary" @click="save">保 存</el-button>
      </span>
    </el-dialog>
      <el-dialog
      :visible.sync="dialogVisible2"
      width="805px"
      :before-close="handleClose2"
    >
      <div class="pup">
        <div class="line"></div>
        <div class="title">添加考试须知</div>
      </div>
      <el-form label-position="right" label-width="120px" class="pupform">
        <el-form-item label="考试须知：">
          <el-input
            v-model="examInfo"
            style="width: 502px"
            type="textarea"
            :rows="5"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="save2">保 存</el-button>
      </span>
    </el-dialog>
     <el-dialog
      :visible.sync="dialogVisible3"
      width="805px"
      :before-close="handleClose3"
    >
      <div class="pup">
        <div class="line"></div>
        <div class="title">添加考试规则</div>
      </div>
      <el-form label-position="right" label-width="120px" class="pupform">
        <el-form-item label="考试规则：">
          <el-input
            v-model="examRule"
            style="width: 502px"
            type="textarea"
            :rows="5"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="save3">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { cityList } from "../../utils/cityCode";
import {
  LevelInfosaveOrUpdate,
  questiontypeList,
  questionsaveOrUpdate,
  questionList,
  questionupdateAllInfonew,
  questiongetAllInfoByIdnew,
  storepageList,
  getpageLevelUserList,
  deleteByIdAndLevelId
} from "../../apis/index";
import { number } from "echarts";
export default {
  data() {
    return {
      removeReviewList:[],
      levelExamId:'',
      examInfo:'',//考试须知
      examRule:'',//考试规则
      storeList:[],
      storeId:'',
      inforObj:{},
      examTime: "",
      citys: [],
      code: "",
      id: "",
      levelId: "",
      qslist: [], //题目类型列表
      des: {},
      dialogVisible: false,
      dialogVisible2:false,
      dialogVisible3:false,
      currentPage: 1,
      pagesize: 10,
      passState:'',
      passList:[
        {
          value: "1",
          label: "通过",
        },
        {
          value: "0",
          label: "未通过",
        },
      ],
      options: [
      
      ],
      pageIndex:1,
      pageSize:10,
      total:0,
      value: 1,
      value1: 1,
      value2: 1,
      value3: 1,
      value4: 1,
      value5: 1,
      input: "",
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      tableData: [],
      name: "",
      introduce: "",
      index: "",
      tableData2: [
       
      ],
      isshow: false,
    };
  },
  watch: {
    input(newdata, olddata) {
      console.log(newdata);
      if (newdata) {
        this.isshow = true;
      } else {
        this.isshow = false;
      }
    },
    storeId(){
      this.student()
    },
    passState(){
      this.student()
    }
  },
  computed: {
    total1() {
      if(this.inforObj[0]){
        return this.inforObj[0].questionScore * this.inforObj[0].questionCount 
      }
    },
    total2() {
      if(this.inforObj[1]){
        return this.inforObj[1].questionScore * this.inforObj[1].questionCount 
      }
    },
    total3() {
      if(this.inforObj[2]){
        return this.inforObj[2].questionScore * this.inforObj[2].questionCount 
      }
    },
    total4() {
      if(this.inforObj[3]){
        return this.inforObj[3].questionScore * this.inforObj[3].questionCount 
      }
       
    },
    total5() {
      if(this.inforObj[4]){
        return this.inforObj[4].questionScore * this.inforObj[4].questionCount  
      }
    },
    totals() {
      return (
        (this.total1?this.total1:0)  + (this.total2?this.total2:0)  + (this.total3?this.total3:0)+ (this.total4?this.total4:0) +(this.total5?this.total5:0) 
      );
    },
  },
  created() {
    let options=[]
    for(let i=0;i<=20;i++){
      options.push({
        value:i,
        label:i
      })
    }
    this.options=options
    // console.log(this.options)
    let codes = cityList(); //获取省市区数据
    this.citys = codes;
    // this.code=['330000', '330100', '330102']
    this.code = "";
    let des = this.$route.query;
    this.des = des;
    console.log(des)
    // this.questype();
    // this.queslist();
    this.getinfo()
    this.store()
    this.student()
  },
  methods: {
   async student(){//获取学员列表
      const params={
        levelId:this.des.id,
        roleId:3,
        searchStr:this.input,
        storeId:this.storeId, 
        passState:this.passState,
        pageIndex:this.pageIndex,
        pageSize:this.pageSize,
        province:this.code[0],
        city:this.code[1],
        area:this.code[2],
        brandId:this.des.brandId
      }
      const {data}=await getpageLevelUserList(params)
      console.log(data)
      if(data.code==200){
       console.log(data)
       this.tableData2=data.data.records
       this.total=Number(data.data.total)
     }else{
       this.$message.error(data.msg)
     }
    },
      async store(){//获取门店列表
      const params={
        pageIndex:1,
        pageSize:9999
      }
     const {data}=await storepageList(params)
     if(data.code==200){
       console.log(data)
       this.storeList=data.data.records
     }else{
       this.$message.error(data.msg)
     }
    },
    async getinfo(){
     const {data}=await questiongetAllInfoByIdnew({levelId:this.des.id,brandId:this.des.brandId})
     console.log(data)
     if(data.code==200){
       data.data.levelQuestionList.forEach(item=>{
         item.levelId=Number(this.des.id)
        //  item.questionCount=Number(0)
        //  item.questionScore=Number(0)
       })
       this.tableData = data.data.reviewInfoList;//设置其他考题
       this.inforObj=data.data.levelQuestionList//试卷设置
       this.examTime=data.data.levelExam.examTime//时间
       this.examInfo=data.data.levelExam.examInfo
       this.examRule=data.data.levelExam.examRule
       this.levelExamId=data.data.levelExam.id
       console.log(data.data)
     }
     
    },
    saveinfo(){//保存大对象
      const levelQuestionList=this.inforObj
      levelQuestionList.map(item=>{
        item.questionCount=Number(item.questionCount)
      })
      // .map(item=>{
      //   return {
      //     id:item.id,
      //     questionCount:item.num,
      //     questionScore:item.score,
      //     questionTypeId:item.questionTypeId,
      //     levelId:this.des.id
      //   }
      // })
      const params={
          levelId:this.des.id,
          levelInfo:{//时间设置对象
              id:this.des.id,
              roleId:this.des.roleId,
              examTime:this.examTime
          },
          levelQuestionList,
          reviewInfoList:[]
        
      }
      const params2={
        brandId:this.des.brandId,
        levelExam:{
          brandId:this.des.brandId,
          examInfo:this.examInfo,//考试须知
          examRule:this.examRule,//考试规则
          examScore:this.totals,//总分
          examTime:this.examTime,//考试时间设置
          id:this.levelExamId,
          levelId:this.des.id,
        },
        levelId:this.des.id,
        levelQuestionList,
        removeReviewList:this.removeReviewList,
        reviewInfoList:this.tableData
      }
      // console.log(this.tableData)
      // console.log(params2)
     questionupdateAllInfonew(params2).then(res=>{
       console.log(res)
        if(res.data.code==200){
        this.$message.success('操作成功')
      }else{
        this.$message.error(res.data.msg)
      }
     }).catch(err=>{
       console.log(err)
       this.$message.error(err.data.msg)
     })
     
    },
    timeval(obj) {
      let val=obj.target.value
     
      val = val.replace(/[^0-9]/g, ""); //只能输入正整数
      if (val.indexOf(".") < 0 && val != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (val.substr(0, 1) == "0" && (val.length ==2 || val.length==1) ) {
          val = val.substr(1, val.length);
        }
       
        if(val.length>2){//保留三位数
          val = val.substr(0, 3);
        }
          if(val>240){//小于240
          val = '';
        }
      }
      this.examTime=val
    },
    selectcity(value) {
      console.log(value);
      // this.list()
      this.student()
    },
    async questype() {
      //题目类型列表
      const { data } = await questiontypeList();
      let qslist=data.data
      qslist.forEach(item => {
          item.score='',
          item.num=1,
          item.typeId='',
          item.id='',
          item.questionTypeId=''
      });
      this.qslist = qslist;
      console.log(qslist);
    },
    async queslist() {
      //其他考题列表
      const params = {
        levelId: this.des.id,
      };
      const { data } = await questionList(params);
      //  this.qslist=data.data
      this.tableData = data.data;
      // console.log(data);
    },
    go() {
      this.$router.go(-1);
    },
    peopledetails(row,ispass) {
      row.titlelevelName=this.des.name
      row.ispass=ispass
      this.$router.push({ path: "/peopleDetails",query:{row:JSON.stringify(row)} });
    },
   async save2(){//添加考试须知
    // const params={
    //   id:this.inforObj.levelInfo.id,
    //   roleId:this.inforObj.levelInfo.roleId,
    //   examInfo:this.examInfo,
    // }
    //  const {data}=await LevelInfosaveOrUpdate(params)
    //  if(data.code==200){
    //    this.$message.success(data.msg)
    //    this.dialogVisible2=false
    //  }else{
    //    this.$message.error(data.msg)
       this.dialogVisible2=false
    //  }
    },
    async save3(){//添加考试规则
    // const params={
    //   id:this.inforObj.levelInfo.id,
    //   roleId:this.inforObj.levelInfo.roleId,
    //   examRule:this.examRule,
    // }
    //   const {data}=await LevelInfosaveOrUpdate(params)
    //  if(data.code==200){
    //    this.$message.success(data.msg)
    //    this.dialogVisible3=false
    //  }else{
    //    this.$message.error(data.msg)
       this.dialogVisible3=false
    //  }
    },
    async save() {
      //保存
      if (this.name == "" || this.introduce == "") {
        this.$message.error("请补全信息");
        return;
      }
      const params = {
        brandId:this.des.brandId,
        des: this.introduce,
        id: this.id,
        name: this.name,
        levelId: this.des.id,
      };
      // console.log(this.tableData)
      let tableData=this.tableData
      tableData.push(params)
      this.tableData=tableData
      this.dialogVisible = false;
      // const { data } = await questionsaveOrUpdate(params);
      // console.log(data);
      // if (data.code == 200) {
      //   this.$message.success("操作成功");
      //   this.name = this.introduce = "";
      //   this.dialogVisible = false;
      //   this.queslist();
      // } else {
      //   this.$message.error(data.msg);
      // }
      // if(this.index!==''){
      //   console.log(this.index)
      //   let tableData=this.tableData
      //   this.tableData[this.index]={
      //     name:this.name,
      //   introduce:this.introduce
      //   }
      //   this.tableData=tableData
      //   this.$set(this.tableData,this.index,{name:this.name,introduce:this.introduce})

      //   this.$message.success('修改成功')
      // this.name=this.introduce=''
      // this.dialogVisible=false
      // }else{
      //   console.log(this.index)
      //   let obj={
      //   name:this.name,
      //   introduce:this.introduce
      // }
      // this.tableData.push(obj)
      // this.$message.success('添加成功')
      // this.name=this.introduce=''
      // this.dialogVisible=false
      // }
    },
    details(row) {
      //详情
      this.introduce = row.des;
      this.name = row.name;
      this.id = row.id;
      this.levelId = row.levelId;
      this.dialogVisible = true;
    },
   async del(row,index) {
     if(row.id){
        this.removeReviewList.push(row.id)
        console.log(row.id)
     }
    
      //删除
      // const params={
      //   id:row.id,
      //   levelId:row.levelId
      // }
    //  const {data}=await deleteByIdAndLevelId(params)
    //  if(data.code==200){
    //   this.queslist();
    //   this.$message.success("删除成功");
    //  }else{
    //   this.$message.error(data.msg);

    //  }
      this.tableData.splice(index, 1);
    },
    add() {
      //添加
      this.index = this.introduce = this.name = this.id = "";
      this.dialogVisible = true;
    },
    handleClose() {
      //关闭
      this.dialogVisible = false;
    },
     add2() {
      //添加考试须知
      this.dialogVisible2 = true;
    },
    handleClose2() {
      //关闭考试须知
      this.dialogVisible2 = false;
    },
      add3() {
      //添加考试规则
      this.dialogVisible3 = true;
    },
    handleClose3() {
      //关闭考试规则
      this.dialogVisible3 = false;
    },
    prevent(e,index) {
      let val=e.target.value
      var keynum = window.event ? e.keyCode : e.which; //获取键盘码
      if (keynum == 189 || keynum == 190 || keynum == 109 || keynum == 110) {
        this.$message.warning("禁止输入小数以及负数");
        e.target.value = "";
        this.inforObj[index].questionScore=''
      }
       if (val.substr(0, 1) == "0" && (val.length ==2 || val.length==1) ) {
          e.target.value = "";
          this.inforObj[index].questionScore=''
        }
       
        if(val.length>2){//保留2位数
          e.target.value = "";
          this.inforObj[index].questionScore=''
        }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize=val
      this.student()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageIndex=val
      this.student()
    },
    search() {
      console.log("我是搜索");
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-dialog__footer {
  text-align: center;
  button {
    width: 200px;
    height: 54px;
    border-radius: 14px;
  }
}
.pupform {
  /deep/.el-form-item {
    margin: 32px 0 34px;
  }
  /deep/.el-form-item__label {
    font-size: 20px;
  }
}
.pup {
  display: flex;
  align-items: center;

  .line {
    width: 9px;
    height: 22px;
    border-radius: 6px;
    background: #409eff;
    margin-right: 11px;
  }
  .title {
    font-weight: bold;
    font-size: 20px;
  }
}
/deep/.el-input__inner::-webkit-outer-spin-button,
/deep/.el-input__inner::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/deep/.el-input__inner[type="number"] {
  -moz-appearance: textfield;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}
.bottomcontent {
  margin: 60px 57px;
  .title {
    display: flex;
    font-weight: bold;
    font-size: 20px;
    align-items: center;
    margin-bottom: 20px;
    .line {
      width: 9px;
      height: 22px;
      border-radius: 6px;
      background: #409eff;
      margin-right: 11px;
    }
  }
}
.operation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 57px;
  /deep/.el-input__inner {
    height: 43px;
    border-radius: 0;
    text-align: center;
  }
  div:first-child {
    display: flex;
  }
}
/deep/.rh {
  th {
    padding: 0 !important;
    height: 41px;
    line-height: 41px;
  }
  td {
    padding: 0 !important;
    height: 41px;
    line-height: 41px;
  }
}
.content {
  margin: 13px 0;
  background: #fff;
  .nav {
    display: flex;
    font-size: 26px;
    font-weight: bold;
    padding: 23px 0;
    div:first-child {
      width: 57px;
      text-align: center;
      color: #c8c8c8;
    }
  }
  .contentmain {
    margin: 0 57px;
    .title {
      display: flex;
      font-weight: bold;
      font-size: 20px;
      align-items: center;
      margin-bottom: 20px;
      .line {
        width: 9px;
        height: 22px;
        border-radius: 6px;
        background: #409eff;
        margin-right: 11px;
      }
    }
    .row {
      font-size: 16px;
      height: 41px;
      display: flex;
      line-height: 41px;
      div {
        width: 111px;
        text-align: center;
        border: 1px solid #e9e9e9;
        border-left: none;
        /deep/.el-select {
          border: none;
        }
        /deep/.el-input__inner {
          border: none;
          height: 30px;
          padding: 0 48px;
          // text-align: center;
        }
      }
    }
    .row2 {
      font-size: 16px;
      height: 41px;
      display: flex;
      line-height: 41px;
      div {
        width: 111px;
        text-align: center;
        border: 1px solid #e9e9e9;
        border-top: none;
        border-bottom: none;
        border-left: none;
        /deep/.el-select {
          border: none;
        }
        /deep/.el-input__inner {
          // border: none;
          //   height: 30px;
          //   padding: 0 27px;
          text-align: center;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }
    }
  }
}
</style>
